import React from 'react';
import ReactDOM from 'react-dom';
import Builder from './Containers/Builder/Builder';
import TagManager from 'react-gtm-module';
//import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import "./assets/styles/cookie.css";

// const tagManagerArgs = {
//     gtmId: 'GTM-MBP8HKC'
// }

// TagManager.initialize(tagManagerArgs)

ReactDOM.render(<Builder />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
serviceWorkerRegistration.register();