import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";

import Header from "../Header/header";
import Footer from "../Footer/footer";
import ListWrapper from "../ListWrapper/listWrapper"
import Gallery from "../Gallery/gallery"
import TextPage from "../TextPage/textPage";
import ItemDescription from "../ItemDescription/itemDescription";
import SearchWrapper from "../ListWrapper/searchWrapper";
import MainListWrapper from "../ListWrapper/mainListWrapper";
import GalleryMultimedia from "../Gallery/galleryMultimedia";
import Banner from "../Banner/banner";
import AdvancedSearchWrapper from "../ListWrapper/advancedSearchWrapper";
import TextPageContact from '../TextPageContact/textPageContact';

const App = ({ match, location }) => {
  const globalUrl = "https://api.nifc.pl/v2/";
  const lang = window.location.href.split("/")[3];
  window.globalUrl = globalUrl;

  if (lang == "pl") {
    document.getElementById("cookiePLText").style.display = "block";
    document.getElementById("rejectAllPL").style.display = "block";
    document.getElementById("onlyAnalyticalPL").style.display = "block";
    document.getElementById("acceptAllPL").style.display = "block";
  }
  if (lang == "en") {
    document.getElementById("cookieENText").style.display = "block";
    document.getElementById("rejectAllEN").style.display = "block";
    document.getElementById("onlyAnalyticalEN").style.display = "block";
    document.getElementById("acceptAllEN").style.display = "block";
  }

  return (
    <Switch>
      <Route exact path="/pl">
        <Header />
        <MainListWrapper url={globalUrl} main={true} />
        <Footer />
      </Route>

      <Route exact path="/en">
        <Header />
        <MainListWrapper url={globalUrl} main={true} />
        <Footer />
      </Route>

      <Route path={"/:lang(pl|en)/kolekcja/:id"}>
        <Header />
        <ListWrapper url={globalUrl} main={false} />
        <Footer />
      </Route>

      <Route path={"/:lang(pl|en)/wyszukanie/:params"}>
        <Header />
        <SearchWrapper url={globalUrl} main={false} />
        <Footer />
      </Route>

      <Route path={"/:lang(pl|en)/wyszukanie-zaawansowane/:params"}>
        <Header />
        <AdvancedSearchWrapper url={globalUrl} main={false} />
        <Footer />
      </Route>

      <Route path={"/:lang(pl|en)/galeria-zasobu/:type/:id/:number"}>
        <Gallery url={globalUrl} />
      </Route>

      <Route path={"/:lang(pl|en)/galeria-multimedia/:type/:id"}>
        <GalleryMultimedia url={globalUrl} />
      </Route>

      <Route path={"/:lang(pl|en)/opis-zasobu/:type/:id"}>
        <ItemDescription url={globalUrl} />
      </Route>

      <Route path={"/:lang(pl|en)/o-projekcie"}>
        <Header />
        <TextPage
          link={"https://api-chopin.musicsources.pl/page/1?lang=" + lang}
        />
        <Footer />
      </Route>

      <Route path={"/:lang(pl|en)/polityka-prywatnosci"}>
        <Header />
        <TextPage
          link={"https://api-chopin.musicsources.pl/page/2?lang=" + lang}
        />
        <Footer />
      </Route>

      <Route path={"/:lang(pl|en)/deklaracja-dostepnosci"}>
        <Header />
        <TextPage
          link={"https://api-chopin.musicsources.pl/page/3?lang=" + lang}
        />
        <Footer />
      </Route>

      <Route path={"/:lang(pl|en)/kontakt"}>
        <Header />
        <TextPageContact
          link={"https://api-chopin.musicsources.pl/page/4?lang=" + lang}
        />
        <Footer />
      </Route>

      <Route path={"/banner"}>
        <Banner />
      </Route>

      <Route exact path={`*`}>
        {lang === "en" ? <Redirect to="/en" /> : <Redirect to="/pl" />}
      </Route>
    </Switch>
  );
};


function Builder() {
    return (
        <Router>
            <App/>
        </Router>
    );
}

export default Builder;