import React, { useState, useEffect } from 'react';
import axios from "axios";

import styles from './textPageContact.module.scss';
import { FadeOut, Loader } from "../../Components/Loader/loader";
import headerStyles from "../Header/header.module.scss";

function TextPageContact(props) {
    const [data, setDate] = useState(null)

    const splitText = (text) => {
        const phoneRegex = /tel\.\:\s*\(?(\+?\d{1,3}[\s\(\)\d\-]+)\)?/i

        const match = text.match(phoneRegex)

        console.log(match)

        if (match) {
            const phoneNumber = match[0];
            const formattedPhoneNumber = phoneNumber
                .replace(/\D/g, '')
                .replace(/^(\+?)/, '+')

            const [beforePhone, afterPhone] = text.split(match[0])

            return [beforePhone.trim(), phoneNumber.trim(), formattedPhoneNumber.trim(), afterPhone.trim()]
        }
    }

    useEffect(() => {
        document.getElementById("loader").style.display = "flex"
        document.getElementById("loader").style.opacity = "1"
        if (props.link) {
            axios
                .get(props.link)
                .then(res => {
                    res.data.formatedTxt = splitText(res.data.txt)
                    let text = splitText(res.data.txt)

                    console.log(res.data)
                    console.log(res.data.txt)
                    console.log(text)




                    setDate(res.data)




                    FadeOut("loader");
                })
        }
    }, [props.link])

    useEffect(() => {
        let header = document.getElementById("header")
        header.classList.remove(headerStyles.headerWrapped)
        header.classList.add(headerStyles.header)
    }, [])

    return (
        <>
            <Loader />
            {data ? (
                <div className={styles.wrapper}>
                    {data.title ? (
                        <span className={styles.title}>
                            {data.title}
                        </span>
                    ) : null}
                    {data.formatedTxt ?
                        data?.formatedTxt?.map((item, index) => {
                            if (index == 1)
                                return (
                                    <a key={index} href={`tel:${data.formatedTxt[index+1]}`} className={styles.text} >{item}</a>
                                )
                            if (index == 2)
                                return (null)
                            else
                                return (
                                    <div key={index} className={styles.text} dangerouslySetInnerHTML={{ __html: item }} />
                                )
                        })


                        : null}
                </div>
            ) : null}
        </>
    );
}

export default TextPageContact;


